.leaflet-container {
  height: 0px;
}

.leaflet-container {
  height: 0px;
}

/* Estilo base del marcador */
.marker-base {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
  background-size: contain;
}

.marker-blue {
  background-image: url('/src/assets/images/map/marker-blue.png');
}
