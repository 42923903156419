.container-tel {
  height: 3.5rem;
  background-color: #f5f8ff;
  border-radius: 12px !important;
  border-style: solid;
  border-width: 2px;
  border-color: #e4e4e7;
  padding: 6px 0px 6px 10px;
  transition: background-color 0.3s;
}

.container-tel:hover {
  border-color: #a1a1aa;
}

.container-tel.clicked {
  border-color: #0084ff;
}
.react-tel-input .selected-flag {
  padding: 0 0 0 5px !important;
  height: 20px !important;
  width: 30px !important;
}

.input-tel-title {
  color: #0084ff;
  font-size: 0.775rem !important;
  margin-bottom: -2px !important;
}

.react-tel-input .flag-dropdown {
  background-color: #f5f8ff !important;
  border-color: #f5f8ff !important;
}

.react-tel-input .form-control {
  padding-left: 30px !important ;
  height: 20px !important;
}

.react-tel-input {
  width: 90% !important;
  font-family: 'Roboto', sans-serif;
}

.error {
  color: #f34885; /* Color cuando hay errores */
  border-color: #f34885;
}

.error-message {
  color: #f34885;
  font-size: 12px !important;
}

.error-container {
  padding-top: 0.25rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  gap: 0.375rem;
}
