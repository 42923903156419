.shepherd-header {
  padding: 6px 12px !important;
}

.shepherd-title {
  font-size: 16px !important;
  font-weight: 600 !important;
}

.propital-tour-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  color: white;
  background: #0084fe !important;
  border: 2px solid transparent;
  border-radius: 35px;
  font-size: 14px;
}

.propital-tour-button-secondary {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  color: #0084fe;
  background: white !important;
  border: 2px solid #0084fe;
  border-radius: 35px;
  font-size: 14px;
}

.propital-tour-button-secondary:hover {
  color: #0084fe !important;
}

.shepherd-enabled.shepherd-element {
  margin: 10px !important;
}
